<template>
  <div class="activate-site">
    <!-- Header Content -->
    <div class="header-site" style="font-size: 30px">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back</b-button
        >
      </router-link>
      {{ "Duplicate Client Data" }}
    </div>
    <div class="create-center">
      <b-dropdown
        block
        class="my-3"
        variant="outline-primary"
        :text="this.getLabel"
        :lazy="true"
      >
        <b-dropdown-item-button
          v-for="(object, index) in clientCollectionIDs"
          :key="index"
          @click="setClientIDToDuplicate(object.id)"
        >
          {{ object.name }} : {{ object.id }}
        </b-dropdown-item-button>
      </b-dropdown>
      <b-form-input
        @keydown.space.prevent
        v-model="newCollectionName"
        autocomplete="off"
        type="text"
        placeholder="Name for new Client Data"
        required
        class="my-3"
      >
      </b-form-input>
      <b-button
        class="btn-primary"
        type="submit"
        style="margin-right: 5px"
        :disabled="disableSubmitButton"
        @click="callDuplicateClient()"
        >Duplicate Client Data</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clientToDuplicate: "",
      newCollectionName: "",
    };
  },
  computed: {
    getLabel() {
      if (this.clientToDuplicate == "") {
        return "Select Existing Data To Duplicate";
      }
      return this.clientCollectionIDs[
        this.clientCollectionIDs.findIndex((e) => {
          return e.id == this.clientToDuplicate;
        })
      ].name;
    },
    clientCollectionIDs() {
      return this.$store.getters.getClientCollectionIDs;
    },
    disableSubmitButton() {
      return this.clientToDuplicate === "" || this.newCollectionName === "";
    },
  },
  methods: {
    setClientIDToDuplicate(clientID) {
      this.clientToDuplicate = clientID;
    },
    callDuplicateClient() {
      this.$api
        .post(`/duplicateCollection/${this.clientToDuplicate}`, {
          newCollectionName: this.newCollectionName,
        })
        .then(() => {
          alert(
            `New Client Data "${this.newCollectionName}" created from: "${this.getLabel}"`
          );
          this.$router.push({ path: "/" });
        })
        .catch((e) => {
          alert(
            "Error occurred. This name may already exist. If that is not the case, please contact support. " +
              e.response.data.error
          );
        });
    },
  },
};
</script>

<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.header-site {
  padding: 30px;
  background-color: #133353;
  color: white;
}

.create-center {
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  align-content: center;
}

@media only screen and (min-width: 1000px) {
  .create-center {
    width: 1000px;
    display: inline-block;
  }
}
</style>
